










import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class CmxAboutUs extends Vue {
  content: object = {};
  isshow: boolean=false;
  // 获取关于我们cms内容
  getContent () {
    this.$Api.cms.getContentByDevice({ ContentId: 20304, IsMobile: true }).then(result => {
      this.content = result.CMS;
    });
  }
  showLang () {
    if (localStorage.locale === 'E') this.isshow = true;
  }
  mounted () {
    this.getContent();
    this.showLang();
  }
}
