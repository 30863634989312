import { render, staticRenderFns } from "./CmxAboutUs.vue?vue&type=template&id=e8d4fb4c&scoped=true&"
import script from "./CmxAboutUs.vue?vue&type=script&lang=ts&"
export * from "./CmxAboutUs.vue?vue&type=script&lang=ts&"
import style0 from "./CmxAboutUs.vue?vue&type=style&index=0&id=e8d4fb4c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8d4fb4c",
  null
  
)

export default component.exports